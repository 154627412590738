import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

export default function EmailShare ({text, subject, body, siteurl}) {
  return (
    <a 
      href={`mailto:?subject=${subject}&body=${body}%0D%0A%0D%0A${siteurl}`}
      title="Share by Email"
      className="share-link-with-text-container"
    >
      <StaticImage 
        src="../images/icon-email.png" 
        alt="Click here send email" 
        width={40}
      />
      <span>{text}</span>
    </a>
  )
}
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

export default function ClickToCopy ({toCopy, text}) {
  return (
    <div 
      className="share-link-with-text-container"
      onClick={() => {navigator.clipboard.writeText(toCopy)}} 
    >
      <StaticImage 
        src="../images/icon-click-to-copy.png" 
        alt="Click here to copy link" 
        width={40}
      />
      <span>{text}</span>
    </div>
  )
}

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function TwShare(props) {
  const rootStyle = (props.rootStyle ? `share-button ${props.rootStyle}` : 'share-button');
  const linkStyle = props.linkStyle || '';
  const windowSize = `width=${props.w || 600},height=${props.h || 500},location=no,status=no`;
  const srcLink = 'https://twitter.com/share?ref_src=twsrc%5Etfw';

  function openWindow(e) {
    e.preventDefault();
    if (window.open) window.open(srcLink, '_blank', windowSize);
  }

  return (
    <div className={rootStyle} data-href={props.pageUrl}>
      <a href={srcLink} className={linkStyle} onClick={openWindow}>
        <StaticImage 
          src='../images/icon-tw.png' 
          alt="twitter" 
          layout="fixed" 
          width={40}  
        />
      </a>
      <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    </div>
  );
};

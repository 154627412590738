import React from 'react'
import FbShare from './FbShare'
import TwShare from './TwShare'
import ClickToCopy from './ClickToCopy'
import EmailShare from './EmailShare'

export default function ShareBanner ({text, toCopy, clickToCopyText, email, siteurl}) {
  return (
    <div className="share-banner">
      <p className="share-banner-text">{text}</p>
      <div className="share-banner-links">
        <FbShare pageUrl={siteurl} />
        <TwShare />
        <ClickToCopy toCopy={toCopy} text={clickToCopyText}/>
        <EmailShare text={email.emailsharetext} subject={email.subject} body={email.body} siteurl={siteurl} />
      </div>
    </div>
  )
}